import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Container, Heading } from "theme-ui"
import Breadcrumbs from "../components/breadcrumbs"
import BlockParallax from "../components/blockParallax"
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media"

const PageHero = ({ page, image }) => {
  const index = useBreakpointIndex()
  return (
    <Box
    sx={{ position: "relative", mt:["72px","auto"],py: [0], pt:[0,0], backgroundColor: "dark", zIndex: 2 }}
    >
      {image && (
        <Box
          sx={{
            position: "relative",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            minHeight: "200px",
            ".gatsby-image-wrapper": {
              width: "100%",
              height: "auto",
              img: {
                objectFit:"contain!important",
                minHeight: "200px",
              },
            },
            overflow: "hidden",
          }}
        >
          <GatsbyImage image={index > 2 ? image.desktop : image.mobile} alt={image.gatsbyImageData} />
          <Box sx={{ top: "50%", width:"100%", transform: "translateY(-50%)", position: "absolute", }}>
            <Container
              sx={{
                height: "100%",
              }}
            >
              <Heading as="h1" variant="h3" color="light">
                {page.title}
              </Heading>
              <Breadcrumbs page={page} />
            </Container>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default PageHero
