import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  renderRule,
} from "datocms-structured-text-utils"
import { Box, Text, Container, Grid, Flex, Heading } from "@theme-ui/components"
import Layout from "../components/layout"
import { getArticlePath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import ImageGallery from "../components/blocks/imageGallery"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import PageHero from "./pageHero"
import VerticalLines from "../components/verticalLines"
import Embed from "../components/blocks/embed"
import { InboundLink } from "../components/link"
import { ChevronLeft, ChevronRight } from "react-feather"
import LongArrowRight from "../components/LongArrowRight"

const Article = ({ data: { page, articles } }) => {
  const favicon = useFavicon().site.faviconMetaTags
  const [foundNextPrevArticles, setFoundNextPrevArticles] = useState()

  // console.log(page)
  const i18nPaths = page._allSlugLocales.map(path => {
    return {
      locale: path.locale,
      value: getArticlePath(page, path.locale),
    }
  })

  useEffect(() => {
    articles.edges.map(edge => {
      console.log(edge.node.id, page.id && edge)
      if (edge.node.id === page.id) {
        console.log("foundNextPrevArticles", edge)
        setFoundNextPrevArticles(edge)
      }
    })
  }, [])

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths} color="dark">
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <VerticalLines />
      <PageHero page={page} image={page.heroImage} />
      <Box sx={{ position: "relative", zIndex: 4 }}>
        <Container>
          <Grid columns={["1fr"]}>
            <Box
              sx={{
                pt: [3],
                strong: {
                  color: "primary",
                  fontWeight: "500",
                },
              }}
            >
              <Flex
                sx={{
                  textTransform: "capitalize",
                  textAlign: "left",
                  color: "dark",
                  alignItems: "start",
                }}
              >
                <Box>
                  <Heading
                    sx={{
                      lineHeight: ".8",
                      fontSize: [3],
                      color: "dark",
                      fontWeight: "500",
                      textAlign: "left",
                      mr: [4],
                    }}
                  >
                    {page.meta.firstPublishedAt}
                  </Heading>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Box
                sx={{
                  mb: 5,
                  a: {
                    color: "inherit",
                    textDecoration: "underline",
                  },
                  ".gatsby-image-wrapper": { width: "100%", mb: [2, 5] },
                }}
              >
                <GatsbyImage
                  image={page.heroImage.gatsbyImageData}
                  alt={page.heroImage.gatsbyImageData}
                />
                <StructuredText
                  data={page.body}
                  customRules={[
                    renderRule(
                      isHeading,
                      ({ adapter: { renderNode }, node, children, key }) => {
                        return renderNode(
                          () => {
                            return (
                              <Text
                                as={`h${node.level}`}
                                variant={`h${node.level}`}
                                sx={{ mb: 3 }}
                              >
                                {children}
                              </Text>
                            )
                          },
                          { key },
                          children
                        )
                      }
                    ),
                    renderRule(
                      isParagraph,
                      ({ adapter: { renderNode }, node, children, key }) => {
                        return renderNode(
                          () => {
                            return (
                              <Text as="p" mb={3} variant="article">
                                {children}
                              </Text>
                            )
                          },
                          { key },
                          children
                        )
                      }
                    ),
                  ]}
                  renderBlock={({ record }) => {
                    // console.log(record)
                    switch (record.__typename) {
                      case "DatoCmsImageGallery":
                        return (
                          <Box mt={5} mb={5}>
                            <ImageGallery
                              images={record.images}
                              key={record.id}
                            />
                          </Box>
                        )
                      case "DatoCmsVideo":
                        return (
                          <Box mt={5} mb={5}>
                            <Embed code={record.code} fullWidth={true} />
                          </Box>
                        )
                      default:
                        return null
                    }
                  }}
                />
              </Box>
            </Box>
          </Grid>
          {foundNextPrevArticles && (
            <Flex
              sx={{
                py:[4,6],
                justifyContent: "space-between",
              }}
            >
              {foundNextPrevArticles.previous && (
                <InboundLink
                  to={getArticlePath(
                    foundNextPrevArticles.previous,
                    page.locale
                  )}
                  sx={{ textDecoration: "none" }}
                >
                  <Flex sx={{ alignItems: "center" }}>
                    <Box
                      sx={{
                        mr: 3,
                        color: "dark",
                        textTransform:"uppercase"
                      }}
                    >
                      Precedente
                    </Box>
                    <Box sx={{transform:"rotate(180deg)"}}>
                    <LongArrowRight color="dark" />
                    </Box>
                  </Flex>
                </InboundLink>
              )}
              {foundNextPrevArticles.next && (
                <InboundLink
                  to={getArticlePath(foundNextPrevArticles.next, page.locale)}
                  sx={{ textDecoration: "none" }}
                >
                  <Flex sx={{ alignItems: "center" }}>
                    <LongArrowRight color="dark" />
                    <Box
                      sx={{
                        ml: 3,
                        color: "dark",
                        textTransform:"uppercase"
                      }}
                    >
                      Successivo
                    </Box>
                  </Flex>
                </InboundLink>
              )}
            </Flex>
          )}
        </Container>
      </Box>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ArticleQuery($id: String!, $locale: String!) {
    page: datoCmsArticle(id: { eq: $id }) {
      ...ArticleDetails
      ...ArticleAllSlugLocales
      ...ArticleMeta
      meta {
        firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    articles: allDatoCmsArticle(filter: { locale: { eq: $locale } }) {
      edges {
        next {
          id
          locale
          ...ArticleAllSlugLocales
          ...ArticleMeta
          meta {
            firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
          }
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
        node {
          id
          locale
          ...ArticleAllSlugLocales
          ...ArticleMeta
          meta {
            firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
          }
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
        previous {
          id
          locale
          ...ArticleAllSlugLocales
          ...ArticleMeta
          meta {
            firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
          }
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    }
  }

  fragment ArticleMeta on DatoCmsArticle {
    meta {
      firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
    }
  }

  fragment ArticleAllSlugLocales on DatoCmsArticle {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment ArticleDetails on DatoCmsArticle {
    id
    locale
    title
    slug
    model {
      apiKey
    }
    heroImage {
      desktop: gatsbyImageData(
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 40
          fit: "crop"
          ar: "16:5"
          h: "600"
        }
      )
      mobile: gatsbyImageData(
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 30
        }
      )
    }
    ...ArticleBody
  }

  fragment ArticleBody on DatoCmsArticle {
    body {
      value
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsVideo {
          id: originalId
          code
          model {
            apiKey
          }
        }
      }
    }
  }

  fragment ImageGallery on DatoCmsImageGallery {
    images {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
      alt
      title
    }
    model {
      apiKey
    }
  }

  fragment ImageGalleryLightboxDetails on DatoCmsImageGalleryLightbox {
    images {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
      alt
      title
    }
    model {
      apiKey
    }
  }
`
